export const APPLICATION_NAME = "SafeDrive - panel CMS";

const currentDomain = window.location.hostname;

export const BACKEND_URL = (function () {
  // return "http://localhost:3000/"

  switch (currentDomain) {
    case "cms.safedrive.pl":
      return "https://api.safedrive.pl/";
    case "sd-cms-dev.s3-website.eu-central-1.amazonaws.com":
      return "http://sdapi-dev.eu-central-1.elasticbeanstalk.com/";
    case "cms.sp.pl":
      return "https://api.sp.pl/";
    case "localhost":
      return "http://localhost:3000/";
    // return "http://192.168.1.102:3000"
    default:
      return `http://sdapi-dev.eu-central-1.elasticbeanstalk.com/`;
  }
})();

export const AUTH_TOKEN = "jdasdhkSJHDASKDjh";
